<template>
  <div>
    <b-button
      :label="btnLabel"
      icon-left="cart-outline"
      size="is-medium"
      title="Klicken um zum Warenkorb zu gelangen"
      @click="show_shopping_cart"
    ></b-button>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  data: function () {
    return {
      count: 0,
      bus: new Vue()
    };
  },
  mounted() {
    this.bus.$on("sccrefresh", () => this.update_shopping_cart_count());
    this.update_shopping_cart_count()
  },
  methods: {
    show_shopping_cart() {
      this.$router.push({name: 'shopping_cart'})
    },
    update_shopping_cart_count() {
      let count = this.$cookies.get("scc");
      if (count) {
        this.count = count;
      } else {
        this.count = 0;
      }
    },
  },
  computed: {
    btnLabel() {
      let addition = this.count > 0 ? `(${this.count})` : "";
      return `Warenkorb ${addition}`;
    },
  },
};
</script>