<template>
  <div>
    <p v-for="notification in notifications" :key="notification.content" v-html="notification.content"
      style="margin-bottom: 20px;" />
  </div>
</template>

<script>
  import {
    loadPageNotifications
  } from '@/webapi.js'
  export default {
    props: {
      page_id: {
        type: String,
        required: true
      },
      canLoad: {
        type: Boolean,
        required: true
      }
    },
    data: function () {
      return {
        notifications: []
      }
    },
    created() {
      if(this.canLoad) {
        this.load()
      }
    },
    methods: {
      load() {
        let self = this
        loadPageNotifications(this.page_id).then(resp => {
          if (resp.data.state) {
            self.notifications = resp.data.notifications
          }
        })
      }
    },
    watch: {
      canLoad: function (newVal, oldVal) {
        if (newVal) {
          this.load()
        }
      }
    }
  }

</script>
